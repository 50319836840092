import React, { useState } from "react";
import krData from "../data/kr.json";
import SubjectItem from "../components/SubjectItem";
import "../css/homePage.css";
import { Link } from "react-router-dom";

const HomePage = () => {
  const [data] = useState(krData);

  const academicItems = data.map((item) => {
    return <SubjectItem key={item.uid} data={item}></SubjectItem>;
  });

  return (
    <main id="homepage">
      <section id="main-nav-container">
        <div id="self-study-container">
          <div className="design-line"></div>
          <div>
            <ul>
              <li>여러 포스팅이 보고 싶다면?</li>
              <li>
                <Link to={"/series"}>포스팅 보러가기 -></Link>
              </li>
            </ul>
            <div></div>
            <ul>
              <Link to={"/series/1"}>
                <li>독학에 대하여</li>
              </Link>
              <Link to={"/series/2"}>
                <li>휴식에 대하여</li>
              </Link>
            </ul>
          </div>
        </div>

        <div id="tranning-center-container">
          <div className="design-line"></div>
          <div>
            <ul>
              <li>무료수업제공 훈련기관을 알고 싶다면?</li>
              <li>
                <Link to={"/tranining-center"}>훈련기관 보러가기 -></Link>
              </li>
            </ul>
            <div></div>
            <ul>
              <Link to={"#"}>
                <li>AD</li>
              </Link>
              <Link to={"#"}>
                <li>AD</li>
              </Link>
              <Link to={"#"}>
                <li>AD</li>
              </Link>
            </ul>
          </div>
        </div>
      </section>
      <section id="academic-container">
        <div>
          <div id="design-line"></div>
          <p>Academic</p>
        </div>
        <div>{academicItems}</div>
      </section>
    </main>
  );
};

export default HomePage;
