import React, {  useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import "../css/seriesPostDetailPage.css"
import seriesData from "../data/series/series.json";

const SelfLearningDetailPage = () => {
    const { seriesUid, postUid } = useParams();
    const [seriesPostDetailData, setSeriesPostDetailData] = useState({
        "uid":0,
        "title":"",
        "content":""
    });

    useEffect(()=>{
        const series = seriesData.find((item) => item.uid === Number(seriesUid));


        if (series) {
            const post = series.contents.find((item) => item.uid === Number(postUid));
            setSeriesPostDetailData(post || null);
        } else {
            setSeriesPostDetailData(null); 
        }

    },[seriesUid, postUid]);

    return (
        <main id='main-series-post-detail'>
            <p>{seriesPostDetailData.title}</p>
            <div id="content-container" dangerouslySetInnerHTML={{__html:seriesPostDetailData.content}}></div>
        </main>
    );
};

export default SelfLearningDetailPage;