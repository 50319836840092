import React, { useEffect, useState } from 'react';
import "../css/seriesPage.css"
import seriesData from '../data/series/series.json';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const SeriesItem = styled.div`
    width:100%;
    height:480px;
    border-radius:16px;
    overflow:hidden;
    position:relative;
    background-color:black;
    

    p{
        position:relative;
        text-align:center;
        font-size:24px;
        font-weight:bold;
        color:#fff;
        line-height:480px;
        z-index:1;
    }

    img{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        transition-duration:0.2s;
    }

    &:hover > img{
        transform:scale(1.2);
    }

    @media (max-width: 480px) {
        height:160px;


        p{
            font-size:20px;
            line-height:160px;
        }
    }
`

const SeriesListPage = () => {
    const [seriesList, setSeriesList] = useState([{
        "title":"",
        "briefDescription":"",
        "thumbnailUrl":"",
        "contents":[]
    }]);

    useEffect(()=>{
        setSeriesList(seriesData);
    },[]);

    const seriesItem = seriesList.map((element, index)=>{
        return (
            <Link to={`/series/${element.uid}`} key={index}>
                <SeriesItem>
                    <p>{element.title}</p>
                    <img src={element.thumbnailUrl} alt='thumbnail'></img>
                </SeriesItem>
            </Link>
        )
    });

    return (
        <main id='seriespage'>
            <div id="title-container">
                <div id='design-line'></div>
                <p>Posting</p>
            </div>
            <div id='series-container'>
                {seriesItem}
            </div>
        </main>
    );
};

export default SeriesListPage;