import React from "react";
import styled from "styled-components";

const StyledFooter = styled.footer`
  min-width: 1168px;
  height: 160px;
  padding: 0px 16px;
  background-color: #fff;
  color: #a5a5a5;
  font-size: 10px;
  font-family: Spoqa Han Sans Neo;
  box-shadow: 0px -2px 2px #e5e5e5;

  div {
    width: 1168px;
    margin: 0 auto;
    height: 100%;
    padding: 0px 0px 16px 0px;
    box-sizing: border-box;
  }

  div > ul {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 8px;
    height: 100%;
  }

  @media (max-width: 480px) {
    min-width: auto;

    div {
      width: auto;
    }
  }
`;

const Footer = () => {
  return (
    <StyledFooter>
      <div>
        <ul>
          <li>(주)킹메이커스</li>
          <li>제휴 문의 : devtiger.academy@gmail.com</li>
        </ul>
      </div>
    </StyledFooter>
  );
};

export default Footer;
