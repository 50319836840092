import React, { useEffect, useState } from "react";
import "../css/seriesPostListPage.css";
import seriesData from "../data/series/series.json";
import styled from "styled-components";
import { Link, useParams } from "react-router-dom";

const SelfLearningItem = styled.div`
  width: 100%;
  height: 64px;
  background: linear-gradient(to right, #218cf7, #29adef);
  border-radius: 8px;
  padding: 0px 20px;
  box-sizing: border-box;
  line-height: 64px;
  color: #fff;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  @media (max-width: 480px) {
    font-size: 14px;
    height: 48px;
    line-height: 48px;
  }
`;

const SelfLearningPage = () => {
  const { seriesUid } = useParams();
  const [seriesPostListData, setSeriesPostList] = useState({
    uid: "",
    title: "",
    briefDescription: "",
    thumbnailUrl: "",
    contents: [],
  });

  useEffect(() => {
    const filterSeriesData = seriesData.filter((element) => {
      return element.uid === Number(seriesUid);
    });

    setSeriesPostList(filterSeriesData[0]);
  }, [seriesUid]);

  const seriesPostList = seriesPostListData?.contents.map((element) => {
    return (
      <Link to={`/series/${seriesUid}/${element.uid}`} key={element.uid}>
        <SelfLearningItem>{element.title}</SelfLearningItem>
      </Link>
    );
  });

  return (
    <main id="main-series-post-list">
      <div id="title-container">
        <p>{seriesPostListData.title}</p>
        <div>{seriesPostListData.briefDescription}</div>
      </div>
      <div id="series-container">{seriesPostList}</div>
    </main>
  );
};

export default SelfLearningPage;
