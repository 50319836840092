import React, { useEffect, useState } from "react";
import "../css/trainingCenterPage.css";
import { Link } from "react-router-dom";
import adTrainingCenter from "../data/trainingCenter/adTrainingCenter.json";
import trainingCenter from "../data/trainingCenter/trainingCenter.json";

const TrainingCenterPage = () => {
  const [adTrainingCenterData, setAdTrainingCenterData] = useState([
    {
      uid: 0,
      trainingCenterName: "",
      imageUrl: "",
      siteUrl: "",
    },
  ]);
  const [trainingCenterData, setTrainingCenterData] = useState([
    {
      uid: 0,
      trainingCenterName: "",
      siteUrl: "",
    },
  ]);

  useEffect(() => {
    setAdTrainingCenterData(adTrainingCenter);
    setTrainingCenterData(trainingCenter);
  }, []);

  const adTrainingCenterItems = adTrainingCenterData.map((element) => {
    return (
      <Link to={element.siteUrl} key={element.uid} target="_blank">
        <div className="training-center-ad-item">
          <div>
            {element.imageUrl ? (
              <img src={element.imageUrl} alt="trainingCenter"></img>
            ) : (
              <p
                style={{
                  color: "black",
                }}
              >
                AD
              </p>
            )}
          </div>
        </div>
      </Link>
    );
  });

  const trainingCenterItems = trainingCenterData.map((element) => {
    return (
      <Link to={element.siteUrl} key={element.uid} target="_blank">
        <div className="training-center-item">{element.trainingCenterName}</div>
      </Link>
    );
  });

  return (
    <main id="main-training-center">
      <section>
        <div>
          <div id="design-line"></div>
          <p>훈련기관</p>
        </div>
        <div id="container-training-center-ad-item">
          {adTrainingCenterItems}
        </div>

        <div id="design-line"></div>

        <div id="container-training-center-item">{trainingCenterItems}</div>
      </section>
    </main>
  );
};

export default TrainingCenterPage;
