import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const StyledHeader = styled.header`
  min-width: 1168px;
  height: 60px;
  padding: 0px 16px;
  background-color: #fff;
  color: #0658AA;
  line-height: 60px;
  font-family: Spoqa Han Sans Neo;
  box-shadow : 0px 2px 2px #E5E5E5;
  overflow:hidden;

  div > a > div > img{
    width:120px;
    height:120px;
    object-position:0px -24px;
  }

  div > a > div {
    display:flex;
    gap:10px;
  }


  @media (max-width: 480px) {
    min-width: auto;
    font-size:12px;
  }
`;

const Header = () => {
  return (
    <StyledHeader>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "0 auto",
        }}
      >
        <Link style={{ color: "#0658AA" }} to={"/"}>
        <div>
        <img src="/images/logo-header.svg" alt="logo"></img>
        
        <ul>
          <li>  독학을 위한 현명한 선택 KINGMAKERS!
            
          </li>
        </ul>
        
        </div>
        </Link>
        <ul></ul>
      </div>
    </StyledHeader>
  );
};

export default Header;
