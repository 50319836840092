import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Item = styled.ul`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
  box-sizing: border-box;
  width: 100%;
  height: 64px;
  background-color: #218cf7;
  border-radius: 8px;

  li {
    color: #fff;
    font-size: 16px;
  }

  li:first-of-type {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media (max-width: 480px) {
    height: 48px;

    li {
      font-size: 14px;
    }
  }
`;

const CurriculumItem = ({ data }) => {
  return (
    <Link to={data.url} target="_blank">
      <Item>
        <li>{data.title}</li>
        <li>
          <img src="/images/ui/playicon.svg" alt="playicon"></img>
        </li>
      </Item>
    </Link>
  );
};

export default CurriculumItem;
